<template lang="pug">
FcwAccountSettingsAuthTemplate
</template>

<script setup lang="ts">
definePageMeta({
  layout: 'account-settings-auth',
});

const { seoData } = await useCmsAccountSettingsSettings();

useClientSeoMeta(seoData);
</script>
